<template>
  <v-container fluid v-cloak>
    <v-layout>
      <v-flex xs12>
        <div>
          <div class="title font-weight-light text-center">
            Login/Register once and excel in your dental exam preparation.<br>
            Don't worry, we do not spam. Your data is important to us. <br>
            We do not send unnecessary emails and we never sell your data.<br>
            From Google we ask for your name, your email and your photo, that's it.<br>
            Please use Google Chrome, Opera or Firefox.<br>
            Please disable popup blocker before continuing. Sign in by Google only works if popups are disabled.
            <br>

          </div>
          <div class="headline font-weight-light text-center">Ready to start learning?</div>

          <div id="firebaseui-auth-container"></div>
          <!-- <v-btn @click="dothis()">Click here</v-btn> -->
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<!--suppress JSUnusedGlobalSymbols -->
<script>
import firebase from 'firebase/compat/app';
import "../../node_modules/firebaseui/dist/firebaseui.css";
import {APIService} from "../APIService";
import localforage from "localforage";

const apiService = new APIService();


let firebaseui = require("firebaseui");


export default {
  data() {
    return {
      loginSent: false
    }
  },
  metaInfo: {
    title: 'Login to Dental Pockets',
    titleTemplate: '%s - Mastering Dentisty the Easy Way!',
    htmlAttrs: {
      lang: 'en',
      amp: true
    },
    meta: [
      {httpEquiv: 'Content-Type', content: 'text/html; charset=utf-8'},
      {name: 'description', content: 'Login to prepare for your dental exams easily.'}
    ]
  },
  mounted() {
    let self = this;
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      self.$store.commit("setShowProgress", true);
      self.isLoggedIn();

      let uiConfig = {
        signInOptions: [
          {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            scopes: [
              // 'https://www.googleapis.com/auth/contacts.readonly'
            ],
            customParameters: {
              // Forces account selection even when one account
              // is available.
              prompt: 'select_account'
            }

          }
        ],
        privacyPolicyUrl: "https://dentalpockets.com/pp.html",
        tosUrl: "https://dentalpockets.com/tnc.html",

        callbacks: {

          signInSuccessWithAuthResult() {
            // eslint-disable-next-line no-console
            console.log("Callback executed")
            localStorage.setItem("authenticated", "true");

            let user = firebase.auth().currentUser;
            // eslint-disable-next-line no-console
            console.log(user)
            if (user) {
              self.loginOnServer(user);

              self.$store.commit("setUser", user);
              self.$store.commit("setAuthenticated", true);

            }
            // return true;

          },
        },
        // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
        signInFlow: 'popup',
        signInSuccessUrl: '/',

      };

      let ui =
          firebaseui.auth.AuthUI.getInstance() ||
          new firebaseui.auth.AuthUI(firebase.auth());
      // var ui = new firebaseui.auth.AuthUI(firebase.auth());
      ui.start("#firebaseui-auth-container", uiConfig);


      self.isLoggedIn();

    });

  },
  methods: {
    isLoggedIn() {
      let self = this;

      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          self.loginOnServer(user);
// eslint-disable-next-line no-console
//           console.log(user)
          self.$store.commit("setUser", user);
          self.$store.commit("setAuthenticated", true);
        } else {
          // eslint-disable-next-line no-console
          // console.log("No User Signed in")
          // No user is signed in.
          self.$store.commit("setShowProgress", false);

          let user = firebase.auth().currentUser;
          // eslint-disable-next-line no-console
          console.log(user)
          if (user) {
            self.loginOnServer(user);

            self.$store.commit("setUser", user);
            self.$store.commit("setAuthenticated", true);

          }


        }
      });
    },
    loginOnServer(user) {
      // eslint-disable-next-line no-console
      // console.log("loginOnServer")
      const app = this;
      this.$store.commit("setShowProgress", true);

      let name = user.displayName;
      let email = user.email;
      let photoUrl = user.photoURL;
      let emailVerified = user.emailVerified;
      let uid = user.uid;

      let options = {
        name: name,
        email: email,
        photoUrl: photoUrl,
        emailVerified: emailVerified,
        uid: uid,
        isMobile: app.$store.getters.getIsMobile,
      };

      if (!app.loginSent) {
        app.loginSent = true;
        apiService.sendLogin(options).then(data => {


          app.$store.commit("setUid", data.uid);
          app.$store.commit("setForumUserName", data.un);
          // eslint-disable-next-line no-unused-vars
          localforage.setItem('selfUsername', data.un, function (err) {
            // if err is non-null, we got an error
            // eslint-disable-next-line no-console
            // console.log("Error", err);
          });
          apiService.getUserLocation();

          app.$router.push({name: "home"}).catch(err => {
            // eslint-disable-next-line no-console
            console.log(err)
          });
          app.$store.commit("setShowProgress", false);
        });
      }


    }
  }
};
</script>